:root {
  --font-family: "Mycall Gelion, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji";
  --main-layer-background: #fff;
  --second-layer-background: #f4f4f9;
  --line-color: #efefef;
  --primary-text-color: #fff;
  --primary-hover-color: #0b083e;
  --primary-color: #110d60;
  --purpur-color-text-interactive-on-expressive: #fff;
  --purpur-color-background-interactive-expressive: #110d60;
  --purpur-color-background-interactive-expressive-hover: #0b083e;
  --purpur-color-background-interactive-primary: #110d60;
  --purpur-color-text-interactive-on-primary: #fff;
  --purpur-color-text-interactive-primary: #2a2a2b;
  --purpur-color-background-interactive-primary-hover: #0b083e;
  --purpur-color-background-interactive-transparent: transparent;
  --purpur-color-background-interactive-transparent-hover: #fff;
  --purpur-color-border-interactive-primary: #2a2a2b;
  --purpur-color-background-interactive-transparent-active: #433be4;
  --purpur-color-background-interactive-disabled: #433be4;
  --purpur-color-text-weak: #fff;
  --purpur-color-text-default: #222;
  --purpur-color-border-interactive-subtle: red;
  --purpur-color-border-interactive-primary-hover: #0f0;
  --purpur-color-background-tone-on-tone-secondary: #736ee0;
}

/*# sourceMappingURL=index.d506f02d.css.map */
